.card {
    min-height: auto;
    margin-bottom: 2rem;
}

.card p {
    line-height: unset;
}
.card h1::first-letter {
    text-transform: capitalize;
}

.headerContainer {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
}

.formatInput {
    margin-left: auto;
}

.formatInput button {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #069;
    cursor: pointer;
    font-family: Roboto-Regular;
    font-size: 1rem;
    text-decoration: underline;
}

.formatInput button:disabled {
    color: unset;
    cursor: text;
}

.filter {
    margin-left: 1rem !important;
}
.filter input::placeholder {
    font-family: Roboto-Italic !important;
}

.groupLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 3px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.groupName {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    margin-right: 2rem;
}

.groupLineIcon {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
}

.groupLine:hover {
    background-color: var(--blue);
    color: var(--white);
}

.groupLine:hover .blue {
    color: var(--white);
}

.body {
    display: flex;
    flex-direction: row;
    gap: 3rem;
}
.cardsContainer {
    flex: 2;
    display: flex;
    flex-direction: column;
    min-width: 0px;
}

.calendar {
    margin-left: auto;
    padding: 0.5rem;
    background-color: white;
    width: fit-content;
    height: fit-content;
    border-radius: 1rem;
}

.blue {
    color: var(--blue);
}
