.container {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.logoMenuContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 90vh;
    width: 100%;
}

.logo {
    margin-top: 3.5vh;
    height: auto;
    width: 80%;
    display: flex;
}

.horizontalLine {
    margin-top: 3.5vh;
    margin-bottom: 1.5vh;
    width: 80%;
    border-bottom-color: var(--medium-grey);
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.fill {
    flex: 1;
    display: flex;
    min-height: 0;
    width: 100%;
}
.navMenu {
    min-height: 0;
    flex: 1;
    margin-left: 10%;
    margin-right: 10%;
    list-style: none;
    overflow-y: scroll;
}
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-thumb {
    background: #3066a4;
    border-radius: 9px;
}
::-webkit-scrollbar-thumb:hover {
    background: #21497a;
}
::-webkit-scrollbar-track {
    background: #d1d1d1;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #f0f0f0;
}
.navMenuItem {
    height: 7.5vh;
    display: flex;
    align-items: center;
    margin-bottom: 7.5%;
    border-radius: 10px;
    padding-left: 6%;
    padding-right: 6%;
}

.navMenu :last-child .navMenuItem {
    margin-bottom: 0vh;
}

.navMenuItem p {
    color: var(--dark-grey);
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 12%;
}

a {
    text-decoration: none;
}

.navMenu li:hover,
.active {
    background-color: var(--blue);
}

.navMenu li:hover p,
.navMenu li:hover svg,
.active p,
.active svg {
    color: white;
}

ul {
    padding: 0;
}

.disconnectContainer {
    height: 8vh;
    display: flex;
    width: 100%;
    bottom: 0;
    position: absolute;
    cursor: pointer;
}

.disconnectContainer :hover {
    background-color: var(--dark-red);
}

.disconnectBox {
    display: flex;
    flex: 1;
    background-color: var(--red);
    justify-content: center;
    align-items: center;
}

.disconnectContainer span {
    font-family: Roboto-Medium;
    font-size: 1.7vw;
    color: white;
    text-align: center;
    text-transform: uppercase;
}

#signout {
}
