.textInputsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 5%;
}

.contentTextInputContainer {
    margin-right: 5%;
    width: 45%;
    margin-bottom: 20px;
}

.imageGroupContainer {
    margin-left: 5%;
}
.imageGroupContainerFirstRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.imageSelectContainer {
    width: 45%;
    margin-right: 30px;
}

.imageInputContainer {
    width: fit-content;
}
.imageInput p {
    padding-left: 20px;
    padding-right: 20px;
}

.imgPreview {
    width: 45%;
    margin-top: 30px;
}

.imageUploadResult {
    margin-left: 30px;
    color: var(--blue);
}

.audioLanguageContainer {
    margin-left: 5%;
    margin-bottom: 20px;
    width: 40%;
    margin-right: 5%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.audioLabel {
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
}

.audioLabel label {
    text-transform: capitalize;
    margin-bottom: 0px;
    margin-right: 30px;
    margin-left: 30px;
}

.audioLabel input[type="checkbox"] {
    height: 20px;
    width: 20px;
}

.audioPlayer {
    margin-bottom: 20px;
}
.fileInput {
    width: fit-content;
}

.fileInput p {
    padding-left: 20px;
    padding-right: 20px;
}

.checkIcon {
    color: var(--blue);
    margin-right: 20px;
}

.audiosContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
