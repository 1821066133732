.card {
    min-height: auto;
}

.headerContainer {
    margin-bottom: 2rem;
    display: inline-flex;
    align-items: center;
}

.editDistributionContainer {
    display: flex;
    flex-direction: row;
    gap: 6rem;
}

.leftContainer {
    flex: 1;
    min-width: 0px;
}

.rightContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0px;
}

.rightContainer h2 {
    width: 100%;
}

.offerElement {
    border-top: 2px solid var(--blue);
    padding: 0.5rem;
    padding-top: 1rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
}

.column {
    display: flex;
    flex-direction: column;
    min-width: 0px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 1rem;
}

.icon {
    margin-left: auto;
    margin-right: auto;
}

.offerLabel label {
    margin-bottom: 0.25rem;
    font-size: 1rem;
}

.inputsMargin {
    margin-bottom: 1rem;
}

.floatRight {
    float: right;
}

.saveDiscardButtons {
    margin-top: 0px;
}
