.header {
    margin-bottom: 20px;
}

.cardContainer {
    padding-top: 1rem;
    flex-grow: 1;
    margin-bottom: 10px;
}

.filterContainer {
    margin-bottom: 20px;
}

.tableContainer {
    height: 0px;
    flex-grow: 1;
}

.tableContainer td:first-letter {
    text-transform: capitalize;
}

.tableContainer td,
.tableContainer th {
    cursor: pointer;
}

.headerCells {
    background-color: transparent !important;
}
