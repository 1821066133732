/* CARD CONTAINER */
.formContainer {
    margin-top: 20px;
    padding: 1rem;
    background-color: transparent;
    width: 100%;
    perspective: 1000px;
    box-sizing: border-box;
}

.flipper {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    display: grid;
    grid-template-columns: 100% 1fr;
    grid-template-rows: 100% 1fr;
}

.cardFrontContainer,
.cardBackContainer {
    grid-area: 1 / 1 / 2 / 2;
    position: relative;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
    box-sizing: border-box;
}

.cardBackContainer {
    transform: rotateY(180deg);
}

.flip {
    transform: rotateY(180deg);
}

/* INPUTS */

.inputContainer {
    margin-bottom: 40px;
}

.mediumSizeFont {
    font-family: Roboto-Medium;
    font-size: 1rem;
}

input[type="checkbox"]:hover {
    cursor: pointer;
}

input[name="projectName"] {
    width: 100%;
    border: 0;
    height: 30px;
    font-size: 20px;
    color: var(--medium-grey);
    height: 50px;
    border: 0;
    border-bottom: 1px solid var(--blue);
}

input[name="projectName"]:focus {
    border-bottom: 2px solid var(--blue);
    outline: none;
    color: var(--blue);
}

.submitButton {
    width: 120px;
    height: 50px;
    border: 0;
    background-color: var(--blue);
    color: var(--white);
    border-radius: 20px;
    font-family: Roboto-Regular;
    font-size: 1rem;
}
.submitButton:hover {
    cursor: pointer;
}

.formHalfWidthContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* FRONT CARD */
.frontCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.groupCheckboxes {
    margin-left: 40px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.checkboxContainer {
    width: 220px;
    text-align: left;
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    margin-bottom: 20px;
}

.checkboxContainer label {
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-family: Roboto-Light;
    text-transform: capitalize;
}

.checkboxContainer input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.fileInputAndMessageContainer {
    flex: 1;
    margin-right: 40px;
}

.fileInputAndMessageContainer:last-child {
    margin-right: 0px;
}

.fileInput {
    margin-top: 30px;
    margin-bottom: 10px;
}

.fileInputsContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}

.frontCard input[type="submit"] {
    justify-self: flex-end;
    align-self: flex-end;
}

/* BACK CARD */

.backCard {
    display: flex;
    flex-direction: column;
}
.backCardFullContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
}

.backCard label {
    margin-bottom: 10px;
}

.width85 {
    width: 85%;
    box-sizing: border-box;
}

.difficultyRating {
    margin-left: 20px;
}

.nextBackButtonContainers {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    justify-self: flex-end;
}

.backButtonContainer {
    display: flex;
    height: 50px;
    margin-right: 25px;
}

.smallText {
    margin-top: 10px;
    font-size: 0.8rem;
    text-align: left;
    line-height: 1rem;
    font-family: Roboto-LightItalic;
}
