.container {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.rightCardContainers,
.leftCardContainers {
    width: 40%;
}

.cardContainer {
    margin-bottom: 50px;
}

.cardContainer a:hover {
    color: var(--blue);
    font-family: Roboto-Medium;
}
