.videoInputContainer {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: flex-start;
    margin-top: 40px;
}

.videoInput {
    flex: 1;
    margin-left: 30px;
    align-items: flex-end;
}

.videoInput:first-of-type {
    margin-left: 20px;
}

.deleteIcon {
    align-self: center;
}

.deleteIcon:hover {
    color: var(--red);
}

.icon {
    cursor: pointer;
    color: var(--medium-grey);
    height: 25px;
    width: 25px;
}

.addIcon {
    margin-left: 10px;
    color: var(--blue);
}

.addIcon:hover {
    height: 30px;
    width: 30px;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inputlabel {
    margin-bottom: auto;
}
