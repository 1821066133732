.textInputContainer {
    width: 100%;
    min-height: fit-content;
    flex-grow: 1;
}

.textField {
    width: 100%;
}

.textInputContainer textarea,
.textInputContainer input {
    margin-bottom: 0.1rem;
    font-family: Roboto-Light;
    margin-left: 1rem;
}

.short {
    width: clamp(50px, 20%, 100px);
}
