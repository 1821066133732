.modal {
    display: flex;
    justify-content: center;
    align-items: center;
}
.cardContainer {
    width: 60vw;
    justify-self: center;
    align-self: center;
    height: 80vh;
}

.bodyContainer {
    overflow-y: auto;
}

.cardContainer h1 {
    text-align: center;
}

.cardContainer h2 {
    margin-top: 20px;
}

.cardContainer label {
    text-transform: capitalize;
}

.nextBackButtonContainers {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 50px;
    margin-right: 50px;
}

.backButtonContainer {
    display: flex;
    height: 50px;
    margin-right: 25px;
}

.submitButton {
    width: 120px;
    height: 50px;
    border: 0;
    background-color: var(--blue);
    color: var(--white);
    border-radius: 20px;
    font-family: Roboto-Regular;
    font-size: 1rem;
}
.submitButton:hover {
    cursor: pointer;
}

.header {
    position: relative;
}

.header h1 {
    color: var(--blue);
}

.headerButtonsContainer {
    position: absolute;
    right: 0;
    top: 0;
}

.headerButtons {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}
.headerButtons:last-child {
    margin-right: 0px;
}
