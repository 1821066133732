.container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

object {
    margin-bottom: 20px;
}

h2 {
    margin-bottom: 20px;
}

.buttonContainer {
    margin-right: 20px;
}

.buttonContainer:last-child {
    margin-right: 0px;
}

.rowButtonsContainer {
    display: flex;
    flex-direction: row;
}
