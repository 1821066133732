.textInputsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 5%;
}

.contentTextInputContainer {
    margin-right: 5%;
    width: 45%;
    margin-bottom: 20px;
}

.imageGroupContainer {
    margin-left: 5%;
}
.imageGroupContainerFirstRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.imageSelectContainer {
    width: 45%;
    margin-right: 30px;
}

.imageInputContainer {
    width: fit-content;
}
.imageInput p {
    padding-left: 20px;
    padding-right: 20px;
}

.imgPreview {
    width: 45%;
    margin-top: 30px;
}

.imageUploadResult {
    margin-left: 30px;
    color: var(--blue);
}

.fileInput {
    width: fit-content;
}

.fileInput p {
    padding-left: 20px;
    padding-right: 20px;
}
