.header {
    display: inline-flex;
    gap: 1rem;
    align-items: baseline;
    margin-bottom: 2rem;
    width: 100%;
    min-width: 0px;
}

.header h1 {
    white-space: nowrap;
}
.smallText {
    font-size: 1rem;
    line-height: unset;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    margin-right: 2rem;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    gap: 8rem;
    padding: 2rem;
}

.halfButtonContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.participantsCard {
    margin: 0px 5rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}

.button {
    justify-content: flex-start !important;
}

.kidImage {
    height: 5rem;
    width: 5rem;
}

.kidColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 8rem;
}

.kidName {
    font-size: 0.9rem;
    overflow-y: clip;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
}
