.container {
    flex: 1;
    width: 100%;
    flex-direction: column;
    display: flex;
}
.inputContainer {
    width: 100%;
    color: var(--blue-high-opacity);
    border-radius: 20px;
    background-color: var(--light-grey);
    border: 1px solid #b2b2b2;
    box-sizing: border-box;
    display: flex;
}

.inputContainer p {
    line-height: 25px;
}

.fileName {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
    justify-content: center;
}

.fileName p {
    font-family: Roboto-Medium;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
}
.borderRadius {
    border-radius: 20px;
}

.dragAccept {
    border: 3px solid var(--blue);
    color: var(--blue);
}

.dragReject {
    border: 3px solid var(--red);
    color: var(--red);
}

.inputContainer .icon {
    width: auto;
    height: 40%;
    max-width: 100%;
}

.message {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
}

.noOutline:focus {
    outline: none;
    border: 3px solid var(--blue);
}

.blueBackground {
    background-color: var(--blue);
    color: var(--white);
}

.message p {
    text-align: center;
}
