.fullWidth {
    width: 100%;
    min-height: 2.5rem;
}

.fullWidth p {
    margin-left: 1rem;
    font-family: Roboto-Light;
}

.formControl {
}

.selected {
    display: flex;
    flex-wrap: wrap;
    text-transform: capitalize;
}

.selected p {
    margin-left: 20px;
    margin-right: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.chip {
    margin: 2px;
    background-color: var(--blue) !important;
    color: var(--white) !important;
}

.noLabel {
    margin-top: 10px;
}

MenuItem {
    margin-right: 5px;
}

.selectBorder {
    border: 1px solid var(--medium-grey);
    /* 	border-radius: 5px;
 */
}

.selectBorder:before {
    border-bottom: none !important;
    border-color: var(--blue);
}

.selectBorder:after {
    border-color: var(--blue);
    border-bottom: none !important;
}

.selectBorder:hover {
    border-color: var(--blue);
    border-width: 2px;
}

.selectBorder:hover svg {
    color: var(--blue);
}

.selectBorder:focus {
    border-color: var(--blue);
    border-width: 2px;
}
