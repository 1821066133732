.levelItemContainer {
    border-radius: 20px;
    background-color: white;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--medium-grey);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.levelItemContainer:hover {
    background-color: var(--blue);
    color: white;
}

.cardContainer {
    margin-top: 2rem;
    background-color: white;
    height: fit-content;
    margin-bottom: 5px;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}
.icon {
    margin-right: 10px;
    height: 25px;
    width: 25px;
}

.icon:hover {
    cursor: pointer;
}

.icon:last-child {
    margin-right: 0px;
}

.handlerIcon {
    height: 25px;
    width: 25px;
}

.plusButton {
    color: var(--blue);
    height: 35px;
    width: 35px;
    cursor: pointer;
}

.title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.title h1 {
    margin-right: 20px;
    margin-bottom: 0px;
}

.pathContainer {
    margin-bottom: 40px;
}

.draggableContainer {
    margin-left: 50px;
    margin-right: 50px;
    flex-grow: 1;
    overflow-y: auto;
}

.handlerContainer {
    margin-right: 30px;
    display: flex;
    align-items: center;
}

.saveDiscardButtonsContainer {
    margin-top: 20px;
    margin-left: auto;
    display: flex;
}

.buttonContainer {
    margin-right: 20px;
}

.newProjectModal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalBodyContainer {
    width: 60%;
    background-color: white;
    padding: 3rem;
}

.modalBodyContainer h1 {
    text-align: center;
    margin-bottom: 3rem;
    color: var(--blue);
}

.inputContainer {
    margin-bottom: 40px;
}

.mediumSizeFont {
    font-family: Roboto-Medium;
    font-size: 1rem;
}

.halfContainer {
    flex: 1;
    margin-right: 10%;
}

.halfContainer:last-child {
    margin-right: 0%;
}

.formContainer {
    display: flex;
    flex-direction: row;
    padding-left: 30px;
    padding-right: 30px;
}

.closeButton {
    position: absolute;
    top: 3rem;
    right: 3rem;
}

.closeButton {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
}

.closeButton:hover {
    color: var(--blue);
}
