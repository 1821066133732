.calendarContainer {
    margin-left: 3rem;
    margin-right: 3rem;
    padding: 1rem;
    background-color: white;
    width: fit-content;
    height: fit-content;
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.blue {
    color: var(--blue);
}

.calendar {
    width: 50%;
}
.headerContainer {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
}

.legendContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.legendElementContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.legendColor {
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 0.4rem;
    margin-right: 1rem;
}
