.container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.card {
    width: 50%;
    min-width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rating {
    color: var(--blue) !important;
}

.rating label span svg {
    height: 3rem;
    width: 3rem;
}
.textFeedback {
    width: 80%;
}

.feedbackTitle {
    margin-top: 2rem;
}
