.imageContainer {
    margin-top: 5px;
    object-fit: contain;
    height: 150px;
    width: 200px;
    border: 1px solid var(--blue);
    box-sizing: border-box;
}

.gridList {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: 10px;
    margin-left: 40px;
}

.newImagesContainer {
    width: 40%;
    margin-top: 50px;
    margin-left: 40px;
}

.uploadImagesButton {
    margin-top: 20px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}
