.header {
    display: flex;
    position: relative;
    margin-bottom: 1.5vw;
    box-sizing: border-box;
    align-items: center;
}

@media screen and (min-width: 1600px) {
    .header {
        margin-bottom: 30px;
    }
}

.cardsContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
}
.leftCardContainer,
.rightCardContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.buttonContainer {
    display: flex;
    width: 28vw;
    height: 3.94vw;
    margin-right: 3.75vw;
}

.buttonContainer:last-child {
    margin-right: 0px;
}

.avatarContainer {
    width: 8vw;
    height: 8vw;
    /* 	position: fixed;
	top: 1.4vw;
	right: 4.69vw;
 */
}
.cardComponent {
    display: flex;
    height: 100%;
    width: 34vw;
    margin-bottom: 50px;
}

.cardComponent h2 {
    margin-bottom: 1vw;
}

@media screen and (min-width: 1600px) {
    .cardComponent h2 {
        margin-bottom: 15px;
    }
}

.cardComponent p {
    margin-left: 47px;
}

.footer {
    position: fixed;
    bottom: 1vh;
    width: 73.8vw;
    color: var(--blue);
    text-align: center;
    background-color: var(--light-grey);
    padding-top: 1vh;
}

.footer p {
    font-family: Roboto-Italic;
}

.bold {
    font-family: Roboto-BoldItalic;
}
