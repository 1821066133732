.container {
    background-color: var(--white);
    display: flex;
    border-radius: 20px;
    padding: 2.5rem;
    flex-direction: column;
    position: relative;
    min-height: 0;
}

.cardWithHeader {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-top: 1.04vw;
    padding-bottom: 1.04vw;
}

.container h1 {
    margin-bottom: 1.72vw;
}

.iconContainer {
    position: absolute;
    width: 2.6vw;
    height: 2.6vw;
    display: flex;
    right: 2.6vw;
}

@media screen and (min-width: 1600px) {
    .iconContainer {
        width: 50px;
        height: 59px;
    }
}

.headerContainer {
    background-color: var(--blue);
    border-radius: 20px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 20px;
    padding-left: 40px;
    color: white;
}

.headerContainer h3 {
    font-family: Roboto-Regular;
}

/* .extraPadding {
	padding-left: 14px;
}
 */
a {
    color: inherit;
}

.closeContainer {
    position: absolute;
    right: 30px;
    top: 30px;
    color: var(--dark-grey);
    stroke: var(--dark-grey);
}

.closeContainer:hover {
    cursor: pointer;
    color: var(--blue);
    stroke: var(--blue);
}

.close {
    height: 50px;
    width: 50px;
}
