.card {
    min-height: auto;
    margin-bottom: 2rem;
}

.headerContainer {
    margin-bottom: 2rem;
}

.saveDiscardButtons {
    margin-top: 0px;
}

.topInput {
    width: 50%;
}

.cardInterior {
    margin-top: 1rem;
}

.groupInformationFieldsContainer {
    display: flex;
    flex-direction: row;
    gap: 6rem;
    margin-left: 2rem;
}

.leftContainer {
    flex: 1;
    min-width: 0px;
}

.rightContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0px;
    justify-content: flex-start;
    align-items: flex-start;
}

.inputsMargin {
    margin-bottom: 1rem;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.levelsContainer p {
    margin-right: 2rem;
}

.levelsContainer span {
    font-family: Roboto-Medium;
    font-size: 0.9rem;
}

.levelsCard {
    width: fit-content;
    min-width: 30%;
}

.levelsCard p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.dayHourPickerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.dayHourPicker {
    display: flex;
    flex-direction: row;
    min-width: 0px;
    width: 100%;
    gap: 1rem;
    align-items: flex-end;
}

.selectDay {
    width: 30%;
    flex-grow: 1;
}

.selectHour {
    width: fit-content;
    flex-grow: 0;
}

.centerIcon {
    margin: auto;
}

.rightIcon {
    margin-left: 0.5rem;
    margin-bottom: 10px;
}

.fullWidth {
    width: 100%;
}

.centerLevels {
    align-items: center;
}

.centerLevels label {
    margin-bottom: 0px;
}

.cardHeader {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
}

.cardHeader h2 {
    margin-bottom: 0px;
}

.noMarginTop {
    margin-top: 0px;
}

.flexgrow {
    flex-grow: 1;
}

.participantsLevelsContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
}

.teamContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: solid 2px var(--blue);
}

.teamContainer:first-of-type {
    border-top: none;
}

.teamContainer p {
    white-space: nowrap;
    font-family: Roboto-Medium;
}
.participantsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 2rem;
    gap: 1rem;
    width: min-content;
    flex-grow: 1;
}

.participantsContainer p {
    font-family: Roboto-Light;
}

.participant {
    display: flex;
    width: 25%;
    min-width: 0px;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.participantImage {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
}

.deleteTeam {
    margin-right: 1rem;
    height: 1rem;
    width: 1rem;
}
