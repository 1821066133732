html {
    font-size: 1.3vw;
}

body {
    margin: 0;
    font-family: Roboto-Regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--dark-grey);
    font-size: 1em;
}

code {
    font-family: Roboto-Regular;
}

h1 {
    font-family: Roboto-Bold;
    font-size: 1.46em;
    height: fit-content;
    margin: 0;
}

h2 {
    font-family: Roboto-Bold;
    font-size: 1.23em;
    height: fit-content;
    margin: 0;
}

h3 {
    font-family: Roboto-Medium;
    font-size: 1em;
    height: fit-content;
    margin: 0;
}

p {
    height: fit-content;
    font-size: 1em;
    margin: 0;
    line-height: 2.6vw;
}

@media screen and (min-width: 1600px) {
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 35px;
    }
    h3 {
        font-size: 30px;
    }
    body {
        font-size: 30px;
    }
}
:root {
    --light-grey: #f5f5f5;
    --medium-grey: #707070;
    --dark-grey: #5a5a5a;
    --very-dark-grey: #434343;
    --red: #d26060;
    --dark-red: #994848;
    --white: #ffffff;
    --blue: #3066a4;
    --dark-blue: #204570;
    --medium-blue: #7eb3f1;
    --light-blue: #c2dbf8;
    --very-light-blue: #eaf4ff;
    --blue-high-opacity: rgba(48, 102, 164, 0.86);
    --transparent-grey: rgba(90, 90, 90, 0.09);
    --yellow: #f7b801;
    --high-opacity-grey: rgba(90, 90, 90, 0.8);
}

@font-face {
    font-family: "Roboto-Regular";
    src: url("./assets/fonts/roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Medium";
    src: url("./assets/fonts/roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-MediumItalic";
    src: url("./assets/fonts/roboto/Roboto-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Bold";
    src: url("./assets/fonts/roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Black";
    src: url("./assets/fonts/roboto/Roboto-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Italic";
    src: url("./assets/fonts/roboto/Roboto-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-BoldItalic";
    src: url("./assets/fonts/roboto/Roboto-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Light";
    src: url("./assets/fonts/roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-LightItalic";
    src: url("./assets/fonts/roboto/Roboto-LightItalic.ttf") format("truetype");
}
