.header {
    display: inline-flex;
    gap: 1rem;
    align-items: baseline;
    margin-bottom: 2rem;
    width: 100%;
    min-width: 0px;
}

.header h1 {
    white-space: nowrap;
}
.smallText {
    font-size: 1rem;
    line-height: unset;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    margin-right: 2rem;
}

.downloadContainer {
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.starsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.idCell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
}

.centerText {
    text-align: center !important;
}

.center {
    margin-left: auto;
    margin-right: auto;
}

.headerText {
    color: var(--white) !important;
}

.tableHeader {
    background-color: var(--blue);
}

.feedbackButton {
    color: var(--red);
    text-decoration: underline;
    cursor: pointer;
}

.downloadButton {
    color: var(--blue);
    cursor: pointer;
}

.lock {
    margin-left: 1rem;
    cursor: pointer;
}
