.bodyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.cardContainer {
    width: 80%;
    align-self: center;
    margin-bottom: 50px;
}
.cardContainer:last-child {
    margin-bottom: 10px;
}

label {
    display: inline-block;
    margin-bottom: 10px;
}

.inputContainer {
    margin-bottom: 40px;
}

.mediumSizeFont {
    font-family: Roboto-Medium;
    font-size: 1rem;
    color: var(--dark-grey);
}

.halfContainer {
    flex: 1;
    margin-right: 10%;
}

.halfContainer:last-child {
    margin-right: 0%;
}

.formContainer {
    padding-left: 30px;
    padding-right: 30px;
}

.horizontal {
    display: flex;
    flex-direction: row;
}

.fakeInput {
    border-bottom: 1px solid var(--blue);
}
.fakeInput .icon {
    float: right;
    cursor: pointer;
}
.fakeInput .icon:hover {
    color: var(--blue);
}

.submitButton {
    width: 100px;
    height: 50px;
    border: 0;
    background-color: var(--blue);
    color: var(--white);
    border-radius: 20px;
    font-family: Roboto-Regular;
    font-size: 1rem;
    margin-left: auto;
}
.submitButton:hover {
    cursor: pointer;
}

.submitButtonContainer {
    display: flex;
    margin-left: auto;
}

.draggableContainer {
    margin-left: 50px;
    margin-right: 50px;
    height: 65vh;
}

.stepItemContainer {
    background-color: white;
    margin-bottom: 10px;
    border: 1px solid var(--medium-grey);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stepHeaderContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid var(--medium-grey);
    border-radius: 20px;
    z-index: 1;
    padding: 10px;
    box-sizing: border-box;
}

.stepHeaderContainer:hover {
    background-color: var(--blue);
    color: white;
}
.handlerContainer {
    margin-right: 30px;
    display: flex;
    align-items: center;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

.icon {
    margin-right: 10px;
    height: 25px;
    width: 25px;
}

.icon:hover {
    cursor: pointer;
}

.saveDiscardButtonsContainer {
    margin-top: 20px;
    margin-left: auto;
    display: flex;
}

.buttonContainer {
    margin-right: 20px;
}

.accordion {
    border: 1px solid !important;
    -webkit-box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.24) !important;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.24) !important;
}

.accordionDetails {
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    padding-left: 3rem !important;
}

.stepsTitleContainer {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1rem;
}

.stepsTitleContainer h1 {
    width: fit-content;
    margin-bottom: 0px;
}
.addIcon {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 0.5rem;
    color: var(--blue);
    cursor: pointer;
}
