.container {
    display: flex;
    cursor: pointer;
}

.fillContainer {
    flex-grow: 1;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    min-width: 0px;
    min-height: 50px;
}

.button p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 20px;
    margin-right: 20px;
}

.solid {
    background-color: var(--blue);
    color: white;
}

.greyOutline {
    background-color: transparent;
    color: var(--medium-grey);
    border: 2px solid var(--medium-grey);
    box-sizing: border-box;
}

.blueOutline {
    background-color: transparent;
    color: var(--blue);
    border: 2px solid var(--blue);
}

.saveDiscardButtonsContainer {
    margin-top: 20px;
    margin-left: auto;
    display: flex;
    gap: 20px;
}

.paddingButton {
    padding: 0px 1.5rem;
}

.disabled {
    background-color: var(--dark-grey);
}
