.container {
    width: 100%;
    height: 100%;
    background-color: var(--grey);
    display: flex;
    align-items: center;
    justify-content: center;
}

.box {
    width: 80%;
    max-width: 1000px;
    height: clamp(100px, 80%, 80%);
    background-color: white;
    border-radius: 1rem;
    display: flex;
}

.imageContainer {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.loginContainer {
    flex: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

/** IMAGES ON THE LEFT SIDE */

.robotsImage {
    width: 80%;
}
.logoImage {
    width: 50%;
}

/** LOGIN ON THE RIGHT SIDE */

.loginContainer h1 {
    font-family: Roboto-Black;
    font-size: 1.5rem;
    color: var(--dark-grey);
    margin-bottom: 2.5rem;
}

.username {
    margin-bottom: 1.5rem;
}

.password {
    margin-bottom: 1rem;
}

.textfield {
    width: 80%;
}
.textfield::after {
    border-bottom-color: var(--blue) !important;
}

.textfield input {
    margin-left: 1rem;
    color: var(--dark-grey);
    font-family: Roboto-Light;
}
.textfield input::placeholder {
    font-family: Roboto-Light;
}

.fieldsIcon {
    color: var(--dark-grey);
    cursor: pointer;
}

.forgotten {
    font-family: Roboto-Light;
    text-decoration: underline;
    margin-bottom: 2rem;
    font-size: 0.8rem;
    cursor: pointer;
}

.submitButton {
    background-color: var(--medium-blue);
    padding: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: fit-content;
    text-align: center;
    border-radius: 0.5rem;
    cursor: pointer;
}
.submitButton:hover {
    background-color: var(--blue);
}
.submitButton p {
    color: white;
}

.loading {
    width: 80%;
}

.error {
    margin-top: 0.5rem;
}
