.sliderContainer {
    flex-grow: 1;
    padding-top: 50px;
    box-sizing: border-box;
    padding-left: 25px;
}

.blue {
    color: var(--blue) !important;
}

.red {
    color: var(--red) !important;
}

.yellow {
    color: var(--yellow) !important;
}
