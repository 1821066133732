@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

/* @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
 */
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: var(--white);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.app-container {
    display: flex;
    flex: 1;
    width: 100vw;
    height: 100vh;
    background-color: var(--light-grey);
}

.app-navigation-container {
    display: flex;
    flex: 1;
    background-color: var(--white);
}

.app-body-container {
    display: flex;
    flex: 4;
    min-width: 0px;
}

.app-body-container-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    margin: 1rem;
    padding: 2.1vw;
    box-sizing: border-box;
}
@media screen and (min-width: 1600px) {
    .app-body-container-box {
        margin: 60px;
    }
    .app-navigation-container {
        max-width: 400px;
    }
}

.navbar-icon {
    height: auto;
    max-height: 80%;
    width: 20%;
    color: var(--dark-grey);
}

.shadow {
    -webkit-box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.24);
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.24);
}

.iconCard-icon {
    width: 100%;
    height: 100%;
    color: var(--blue);
}

.loading {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bold {
    font-family: Roboto-Bold;
}

.fill {
    width: 100%;
    height: 100%;
}

.fit-cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.medium {
    font-family: Roboto-Medium;
}

.error {
    text-align: left;
    color: var(--red);
    font-family: Roboto-Medium;
    font-size: 0.8rem;
    line-height: normal;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.overflow-y {
    overflow-y: auto;
}

.a-hover-blue a:hover {
    color: var(--blue);
}

select {
    font-family: Roboto-Regular;
    font-size: 1rem;
}

textarea {
    font-family: Roboto-Regular;
    font-size: 1rem;
}

.capitalize {
    text-transform: capitalize;
}

.icon {
    color: var(--dark-grey);
}
.icon:hover {
    cursor: pointer;
    color: var(--blue);
}
.horizontal {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.text-center {
    text-align: center;
}
.full-width {
    width: 100%;
}

.italics {
    font-family: Roboto-Italic;
}

.dark-grey {
    color: var(--dark-grey) !important;
}

input[type="submit"] {
    width: 120px;
    height: 50px;
    border: 0;
    background-color: var(--blue);
    color: var(--white);
    border-radius: 20px;
    font-family: Roboto-Regular;
    font-size: 1rem;
    cursor: pointer;
}

label {
    font-family: Roboto-Medium;
    font-size: 0.9rem;
}
